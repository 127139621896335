<template>
  <div class="student" ref="student">
    <keep-alive>
      <list-template
          :loading="loading"
          :total="total"
          :current-page="page"
          :table-data="tableData"
          :table-config="tableConfig"
          @onChangePage="changeCurrentPage"
          @onExportData="download"
      >
        <template slot="title">
          <el-form ref="form" :model="form" label-width="0" style="padding-right: 40rem">
            <div class="flex flex-align">
              <el-form-item>
                <el-select size="small" placeholder="筛选支付状态" v-model="form.pay_state" @enter='onSearch' :popper-append-to-body="false">
                  <el-option v-for="option in paySelectConfig" :key="option.id" :label="option.text" :value="option.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select size="small" placeholder="筛选套餐类型" v-model="form.pack_type" :popper-append-to-body="false">
                  <el-option v-for="option in options" :key="option.value" :label="option.label" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                    v-model="form.time_pay"
                    @enter='onSearch'
                    size="small"
                    type="daterange"
                    range-separator="至"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
								<el-input v-model="form.customer_name" placeholder="搜索付款人姓名" size="small" @enter='onSearch'></el-input>
							</el-form-item>
							<el-form-item>
								<el-input v-model="form.student_name" placeholder="搜索学生姓名" size="small" @enter='onSearch'></el-input>
              </el-form-item>
							<el-form-item>
								<el-input v-model="form.teacher_name" placeholder="搜索客户归属人" size="small" @enter='onSearch'></el-input>
							</el-form-item>
              <el-button debounce size="small" type="primary" @click.prevent="onSearch(false)">搜索</el-button>
              <!--              <el-button debounce size="small" type="primary" @click.prevent="download">导出数据</el-button>-->
              <el-button size="small" style="margin-left: 16rem" debounce @click.prevent="reset">重置</el-button>
            </div>
          </el-form>
        </template>
      </list-template>
    </keep-alive>
  </div>
</template>

<script>
import ListTemplate from "@/components/pages/List";
import {mapState} from "vuex";
import { get_2025pack } from '@/api'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:true,
      // 表格搜索配置
      form: {
        pay_state: '',
        pack_type: '',
        time_pay: '',
        customer_name: '',
        student_name: ''
      },
      //支付状态搜索框配置
      paySelectConfig: [
        {id: 'SUCCESS', text: '支付成功'},
        {id: 'TIMEOUT', text: '支付超时'},
        {id: 'FAILURE', text: '支付失败'},
        {id: 'AWAIT', text: '等待支付'},
      ],
      // 表格配置
      tableConfig: [
        {prop: 'pay_state', label: '支付状态'},
				{prop: 'order_type', label: '订单类型'},
        {prop: "pack_type_name", label: "套餐类型"},
				{prop: 'school_name', label: '报名学校'},
				{prop: 'study_school_name', label: '实际就读学校'},
				{prop: 'customer_name', label: '付款人姓名'},
				{prop: 'student_name', label: '学生姓名'},
				{prop: 'teacher_name', label: '当前客户归属人'},
				{prop: 'money_actual', label: '实际支付金额'},
				{prop: 'pay_mode', label: '支付方式'},
				{prop: 'time_pay', label: '支付时间', minWidth: '140rem'},
      ],
      // 总数据量
      total: 0,
      // 表格中的数据
      tableData: [],
      options: []
    }
  },
  computed: {
    ...mapState(['page'])
  },
  components: {
    ListTemplate
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.getConfig()
  },
  methods: {
    getConfig() {
      get_2025pack().then(res => {
        this.options = res
      })
    },
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    // 获取数据
    getData() {
      let params = {...this.form}
      if(this.form.time_pay){
        params.time_pay = this.form.time_pay.join('~')
      }
      this.loading = true;
      this.$_register.get('/api/recruit/order/pay-list', {params: {...params, page: this.page}}).then(res => {
        let data = res.data.data
        if(Array.isArray(data)){
          this.tableData = []
          this.total = 0
        }else{
          this.tableData = data.list
          this.total = data.page.total
        }
      }).finally(()=>this.loading = false)
    },
    // 搜索功能
    onSearch(val) {
      this.$store.commit('setPage', 1)
			console.clear()
      this.getData()
    },

    //重置搜索框
    reset() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = '';
      })
      this.$store.commit('setPage', 1)
      this.getData()
    },

    download(){
      // if (this.tableData.length === 0) {
      //   this.$message({
      //     message: '当前表格暂无数据！',
      //     type: 'warning'
      //   });
      //   return false;
      // }
      let params = JSON.parse(JSON.stringify(this.form))
      if(this.form.time_pay){
        params.time_pay = this.form.time_pay.join('~')
      }
      delete params.page

			this.$_register.get("/api/recruit/order/export-pay-list", {params, responseType: "blob", timeout: 0}).then(res => {
        const filename = '招生支付记录' + '.csv'
        const blob = new Blob([res.data])
        let downloadElement = document.createElement('a')
        let href = window.URL.createObjectURL(blob)
        downloadElement.href = href
        downloadElement.download = decodeURIComponent(filename)
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement)
        window.URL.revokeObjectURL(href)
      })

    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-range-input{
  padding-left: 10rem !important;
  text-align: left !important;
}

::v-deep .el-range-editor--small .el-range-separator{
  min-width: 30rem;
}


.el-date-editor{
  width: 350rem !important;
  max-width: 350rem !important;
}
@media only screen and (min-width: 1440rem){
  .el-date-editor{
    //width: 24vw !important;
  }
}
::v-deep .el-form .el-input {
  width: auto !important;
}

::v-deep .el-form-item {
  margin-right: 20rem !important;
  margin-bottom: 12rem !important;
}

::v-deep .el-button--small {
  padding: 9rem 15rem;
  font-size: 12rem;
  border-radius: 3rem;
}

::v-deep .el-button--small {
  height: 32rem;
  margin-bottom: 12rem;
}
</style>
